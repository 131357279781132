<template>
  <div id="workflowRequestPreview" class="">
    <template v-if="selectedProcess.processId">
      <div class="list q-mb-sm">
        <div class="row q-pa-xs">
          <div class="col-auto q-mt-xs">
            <div class="row">
              <template v-for="tab in tabs">
                <BaseActionButton
                  v-if="
                    (tab.value !== 'VALIDATION' &&
                      tab.value !== 'PAYMENT_TABLE') ||
                    ((tab.value === 'VALIDATION' ||
                      tab.value === 'PAYMENT_TABLE') &&
                      isCustomizedWorkflow)
                  "
                  :key="tab.id"
                  v-tooltip.top="tab.label"
                  is-flat
                  :icon="tab.icon"
                  size="16px"
                  color="primary"
                  class="q-mr-sm linkActions"
                  @click="showLinks(tab.value)"
                >
                  <span v-if="tab.count" class="badge">{{ tab.count }} </span>
                  <q-menu
                    v-if="tab.value === 'TOGGLE'"
                    transition-show="scale"
                    transition-hide="scale"
                    anchor="bottom end"
                    self="top right"
                  >
                    <div class="title-3 border q-pa-xs">
                      <input
                        ref="searchQuery"
                        v-model="searchColumns"
                        type="text"
                        class="q-pa-md"
                        placeholder="Search"
                        @keyup="searchColumnList(searchColumns)"
                      />
                    </div>
                    <BaseScrollbar
                      :height="scrollbarHeight"
                      width="240px"
                      class="q-pb-sm"
                    >
                      <!-- columns -->

                      <ListItem
                        v-for="column in columns"
                        :key="column.id"
                        :icon="
                          column.isVisible
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank-outline'
                        "
                        :class="column.display"
                        :is-selected="column.isVisible"
                        :label="column.label"
                        @click="updateHideColumns(column)"
                      />
                    </BaseScrollbar>

                    <!-- ... -->
                  </q-menu>
                </BaseActionButton>
              </template>
            </div>
          </div>
          <div class="col">
            <div class="row items-center justify-end">
              <template v-if="isPreviewAction">
                <BaseActionButton
                  v-if="
                    processActions.length && $store.state.session.tenantId === 1
                  "
                  v-tooltip.bottom="'Recommendation'"
                  icon="mdi-creation"
                  color="primary"
                  no-border
                  is-flat
                  size="20px"
                  class="q-ml-xs btnColor"
                  @click="summarizeModal = true"
                />
                <div
                  v-for="action in processActions"
                  :key="action"
                  @click="requestAction(action)"
                >
                  <q-chip
                    v-if="
                      action === 'Reject' ||
                      action === 'Terminate' ||
                      action === 'Force Close'
                    "
                    v-tooltip.top="action"
                    outline
                    color="red"
                    class="cursor-pointer chips red-hover icon-only"
                  >
                    <q-avatar
                      icon="mdi-close"
                      class="rotate-90 q-mr-sm"
                      color="red"
                      text-color="white"
                    />
                    <!-- {{ action }} -->
                  </q-chip>

                  <q-chip
                    v-else-if="
                      action === 'Approve' ||
                      action === 'Complete' ||
                      action === 'Rightsize'
                    "
                    v-tooltip.top="action"
                    outline
                    color="green"
                    class="cursor-pointer chips green-hover icon-only"
                  >
                    <q-avatar
                      icon="mdi-check"
                      class=""
                      color="green"
                      text-color="white"
                    />
                    <!-- {{ action }} -->
                  </q-chip>

                  <q-chip
                    v-else-if="action === 'Forward' || action === 'Assign'"
                    outline
                    color="orange"
                    class="cursor-pointer chips orange-hover"
                  >
                    <q-avatar
                      icon="mdi-arrow-right"
                      class=""
                      color="orange"
                      text-color="white"
                    />
                    {{ action }}
                  </q-chip>
                  <q-chip
                    v-else-if="
                      action.indexOf('Reply') > -1 || action === 'Rollback'
                    "
                    outline
                    color="orange"
                    class="cursor-pointer chips orange-hover"
                  >
                    <q-avatar
                      icon="mdi-arrow-left"
                      class=""
                      color="orange"
                      text-color="white"
                    />
                    {{ action }}
                  </q-chip>
                  <q-chip
                    v-else-if="action === 'To Requester'"
                    outline
                    color="pink"
                    class="cursor-pointer chips pink-hover"
                  >
                    <q-avatar
                      icon="mdi-arrow-left"
                      class=""
                      color="pink"
                      text-color="white"
                    />
                    {{ action }}
                  </q-chip>
                  <q-chip
                    v-else
                    outline
                    color="primary"
                    class="cursor-pointer chips primary-hover"
                  >
                    <q-avatar
                      icon="mdi-arrow-right-thick"
                      class=""
                      color="primary"
                      text-color="white"
                    />
                    {{ action }}
                  </q-chip>
                </div>
                <BaseActionButton
                  icon="eva-external-link-outline"
                  color="primary"
                  no-border
                  is-flat
                  size="16px"
                  class="q-ml-xs btnColor"
                  @click="$emit('expand')"
                />
              </template>
              <template v-else>
                <div @click="$emit('expand')">
                  <q-chip
                    outline
                    color="secondary"
                    class="cursor-pointer chips secondary-hover"
                  >
                    <q-avatar
                      icon="eva-external-link-outline"
                      class=""
                      color="secondary"
                      text-color="white"
                    />
                    Expand
                  </q-chip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- <div class="row justify-center q-mb-xs">
          <div class="title-2">{{ form.name }}</div>
        </div> -->
        <div class="row">
          <div class="col">
            <BaseScrollbar height="calc(100vh - 270px)" class="fullBorder">
              <!-- style="height: 80px; overflow: hidden" -->
              <div class="row justify-center">
                <div class="col list q-pa-sm">
                  <div class="row items-center">
                    <table v-if="false" class="q-mb-sm requestDetails">
                      <tbody>
                        <!-- <tr v-if="selectedProcess.createdByName">
                          <td colspan="5">
                            Requested By: {{ selectedProcess.createdByName }}
                          </td>
                        </tr> -->
                        <tr v-if="selectedProcess.requestNo">
                          <td colspan="5">
                            {{ selectedProcess.requestNo }}
                          </td>
                        </tr>
                        <tr>
                          <th v-if="selectedProcess.raisedBy">
                            Requested User Email
                          </th>
                          <th>Requested Date</th>
                          <th>Last Acted By</th>
                          <th>Last Acted On</th>
                          <th v-if="selectedProcess.activityUserEmail">
                            Action User
                          </th>
                        </tr>
                        <tr>
                          <td v-if="selectedProcess.raisedBy">
                            {{ selectedProcess.raisedBy }}
                          </td>
                          <td>{{ $day.format(selectedProcess.raisedAt) }}</td>
                          <td>
                            {{ selectedProcess.transaction_createdByEmail }}
                          </td>
                          <td>
                            {{
                              $day.format(selectedProcess.transaction_createdAt)
                            }}
                          </td>
                          <td v-if="selectedProcess.activityUserEmail">
                            {{ selectedProcess.activityUserEmail }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div
                      class="col-12 q-mb-sm q-pa-sm requestDetails"
                      :class="checkStatusColor"
                    >
                      <div class="row items-center justify-between">
                        <div class="col-auto title-2 row items-center">
                          <BaseIcon
                            :name="checkStatusIcon"
                            :color="checkStatusColor"
                            size="22px"
                            class="q-mr-xs"
                          />
                          {{ selectedProcess.requestNo }}
                        </div>

                        <!-- <div class="col-auto q-mr-sm">
                          <div
                            v-if="selectedProcess.slaResponse"
                            class="slaResponse q-pa-xs q-mb-sm"
                          >
                            Response SLA: {{ selectedProcess.slaResponse }}
                          </div>
                          <div
                            v-if="selectedProcess.slaResolution"
                            class="slaResolution q-pa-xs"
                          >
                            Actual Time: {{ selectedProcess.slaResolution }}
                          </div>
                        </div> -->

                        <div
                          v-if="showAnswerIndicator"
                          class="col-auto answer-count"
                        >
                          <div
                            class="row items-center justify-center q-ml-sm q-mr-sm"
                          >
                            <BaseIcon
                              :name="
                                answerIndicatorList.filledValue
                                  ? 'eva-checkmark-circle-2-outline'
                                  : 'eva-alert-circle-outline'
                              "
                              :color="
                                answerIndicatorList.filledValue
                                  ? 'positive'
                                  : 'warning'
                              "
                              size="16px"
                              class="q-mr-xs"
                            />

                            <span class="label">
                              {{ answerIndicatorList.filledValue }} of
                              {{
                                answerIndicatorList.filledValue +
                                answerIndicatorList.nonFilledValue
                              }}
                              inputs completed
                            </span>
                          </div>
                        </div>

                        <div class="col-auto label">
                          <div class="justify-end">
                            <div class="">
                              Requester: {{ selectedProcess.raisedBy }}
                            </div>
                            <div class="">
                              Request On:
                              {{ $day.format(selectedProcess.raisedAt) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 q-mb-sm q-pt-md stepper">
                      <Stepper
                        :stage-level="stageLevel"
                        :current-step="currentStep"
                      />
                    </div>

                    <table
                      v-if="selectedProcess.jiraIssueInfo.id"
                      class="q-mb-sm jiraDetails"
                    >
                      <tbody>
                        <tr>
                          <th>Project Key</th>
                          <th>REF# ID</th>
                          <th>Status</th>
                          <th>Assignee</th>
                        </tr>
                        <tr>
                          <td>
                            <span
                              class="cursor-pointer text-underline"
                              @click="
                                getJIRALink(selectedProcess.jiraIssueInfo)
                              "
                            >
                              {{ selectedProcess.jiraIssueInfo.key }}
                            </span>
                          </td>
                          <td>
                            {{ selectedProcess.jiraIssueInfo.id }}
                          </td>
                          <td>
                            {{ selectedProcess.jiraIssueInfo.issueStatus }}
                          </td>
                          <td>
                            {{ selectedProcess.jiraIssueInfo.assignee }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <template
                      v-if="
                        selectedProcess.formData &&
                        Object.keys(form.formJson).length
                      "
                    >
                      <table class="mainData">
                        <template v-for="column in columns">
                          <template
                            v-if="
                              selectedProcess.formData.fields[column.id] &&
                              selectedProcess.formData.fields[column.id] != '[]'
                            "
                          >
                            <template v-if="isVisible(column.id)">
                              <template v-if="validateType(column.id)">
                                <!-- <div :key="key" class="row ellipsis">
                                <div class="header col-">
                                  {{ getLabel(key) }}
                                </div>
                                <div class="label col-auto">
                                  <div
                                    v-if="validateValue(value)"
                                    class="forms"
                                  >
                                    {{ value || "-" }}
                                  </div>
                                  <div v-else class="forms">
                                    <a :href="value" target="_blank">
                                      {{ value || "-" }}
                                    </a>
                                  </div>
                                </div>
                              </div> -->
                                <tr :key="column.id">
                                  <td class="tblHeader ellipsis small">
                                    {{ column.label }}
                                  </td>
                                  <td class="ellipsis">
                                    <template
                                      v-if="
                                        validateValue(
                                          selectedProcess.formData.fields[
                                            column.id
                                          ]
                                        )
                                      "
                                    >
                                      <template
                                        v-if="validateDateType(column.id)"
                                      >
                                        <!-- selectedProcess.formData.fields[
                                        column.id
                                      ] || "-" -->
                                        {{
                                          $day.format(
                                            selectedProcess.formData.fields[
                                              column.id
                                            ],
                                            false
                                          )
                                        }}
                                      </template>
                                      <template
                                        v-else-if="
                                          validateFileUpload(column.id)
                                        "
                                      >
                                        <div
                                          v-if="
                                            JSON.parse(
                                              selectedProcess.formData.fields[
                                                column.id
                                              ]
                                            ).length
                                          "
                                        >
                                          <div
                                            v-for="file in JSON.parse(
                                              selectedProcess.formData.fields[
                                                column.id
                                              ]
                                            )"
                                            :key="file.fileName"
                                            class="q-mb-xs"
                                          >
                                            <div class="row items-center">
                                              <div
                                                class="col row items-center"
                                                @click="
                                                  openFileInFileControl(file)
                                                "
                                              >
                                                <!-- <span class="q-mr-sm"
                                                  >{{ index + 1 }}.</span
                                                > -->
                                                <span class="q-mr-sm fileIcon"
                                                  ><FileIcon
                                                    :mime-type="
                                                      fileType(file.fileName)
                                                    "
                                                    class="mini-avatar"
                                                /></span>
                                                <span
                                                  class="cursor-pointer text-underline text-secondary"
                                                  >{{ file.fileName }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                      <template v-else>
                                        {{
                                          removeTags(
                                            selectedProcess.formData.fields[
                                              column.id
                                            ]
                                          ) || "-"
                                        }}
                                      </template>
                                    </template>

                                    <template v-else>
                                      <a
                                        :href="
                                          selectedProcess.formData.fields[
                                            column.id
                                          ]
                                        "
                                        target="_blank"
                                      >
                                        {{
                                          selectedProcess.formData.fields[
                                            column.id
                                          ] || "-"
                                        }}
                                      </a>
                                    </template>
                                  </td>
                                </tr>
                              </template>
                              <template v-else>
                                <template
                                  v-if="
                                    tableValue(
                                      selectedProcess.formData.fields[column.id]
                                    ).length
                                  "
                                >
                                  <tr
                                    v-if="column.label"
                                    :key="'trhead' + column.id"
                                  >
                                    <td colspan="2">{{ column.label }}</td>
                                  </tr>
                                  <tr :key="'tr' + column.id">
                                    <td colspan="2">
                                      <BaseScrollbar>
                                        <table :key="column.id">
                                          <thead>
                                            <tr>
                                              <th
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(
                                                  selectedProcess.formData
                                                    .fields[column.id]
                                                )[0]"
                                                :key="field_key"
                                              >
                                                {{
                                                  getLabel(field_key, "table")
                                                }}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="(
                                                field_item, field_key
                                              ) in tableValue(
                                                selectedProcess.formData.fields[
                                                  column.id
                                                ]
                                              )"
                                              :key="field_key"
                                            >
                                              <td
                                                v-for="(
                                                  row_value, row_key
                                                ) in field_item"
                                                :key="row_key"
                                              >
                                                {{ row_value }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </BaseScrollbar>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </table>
                    </template>
                    <template v-if="selectedProcess.itemData">
                      <table class="mainData">
                        <template
                          v-for="(value, key) in selectedProcess.itemData.items"
                        >
                          <template v-if="isVisible(key)">
                            <!-- <div :key="key" class="col-4 ellipsis">
                            <div class="header">{{ key }}</div>
                            <div class="label">
                              <div v-if="validateValue(value)" class="forms">
                                {{ value || "-" }}
                              </div>
                              <div v-else class="forms">
                                <a :href="value" target="_blank">
                                  {{ value || "-" }}
                                </a>
                              </div>
                            </div>
                          </div> -->
                            <tr :key="key">
                              <td class="tblHeader ellipsis">{{ key }}</td>
                              <td class="ellipsis">
                                <template v-if="validateValue(value)">
                                  {{ removeTags(value) || "-" }}
                                </template>
                                <template v-else>
                                  <a :href="value" target="_blank">
                                    {{ value || "-" }}
                                  </a>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="signList.length" id="approvalSignature">
                <div class="header q-mb-sm">Approval Signature</div>

                <div class="row fullBorder">
                  <template v-for="sign in signList">
                    <div :key="sign.id" class="col-4 q-mb-sm">
                      <img class="imgCenter col" :src="signLink(sign.id)" />
                      <div class="text row">
                        <span class="col-12 text-bold">{{ sign.stage }}</span>
                        <span class="col-12">{{ sign.createdByEmail }}</span>
                        <span class="col-12">{{
                          $day.format(sign.createdAt)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <!-- ... -->
            </BaseScrollbar>
          </div>
        </div>
      </div>
    </template>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="selectedNode === 'inbox' || selectedNode === 'payment'"
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template
        v-if="selectedNode === 'inbox' || selectedNode === 'payment'"
        #upload
      >
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox' || selectedNode === 'payment'
              ? 'calc(100vh - 200px)'
              : ''
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <BaseIcon
                          :name="
                            file.checked
                              ? 'eva-checkmark-circle-2'
                              : 'eva-radio-button-off-outline'
                          "
                          color="secondary"
                          class="q-mr-sm"
                          @click="file.checked = !file.checked"
                        />
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>
                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template
                        v-if="
                          (selectedNode === 'inbox' ||
                            selectedNode === 'payment') &&
                          file.createdBy === $store.state.session.id
                        "
                      >
                        <div class="col-auto">
                          <BaseActionButton
                            v-tooltip.top="'delete'"
                            icon="eva-trash-2-outline"
                            color="red"
                            no-border
                            is-flat
                            size="20px"
                            @click.stop="attachmentDelete(file.id)"
                          />
                        </div>
                        <div class="col-auto">
                          <BaseActionButton
                            v-tooltip.top="'Re-Upload'"
                            icon="mdi-file-upload"
                            color="primary"
                            no-border
                            is-flat
                            size="20px"
                            @click.stop="
                              {
                                reuploadId = file.id;
                                reupload(file.name);
                              }
                            "
                          />
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <div class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      (selectedNode === 'inbox' ||
                        selectedNode === 'payment') &&
                      file.createdBy === $store.state.session.id
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file.id)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="
        selectedNode === 'inbox' || selectedNode === 'payment' ? '30' : '0'
      "
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div
        v-if="selectedNode === 'inbox' || selectedNode === 'payment'"
        class="col"
      >
        <div class="row">
          <template v-if="checkTenant()">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="q-pr-sm"
                  :class="attachComment ? 'col-auto' : 'col'"
                />

                <CheckboxField
                  v-if="selectedProcess.stageType !== 'START' && !attachComment"
                  v-model="notifyInitiator"
                  label="Notify Initiator/Requester"
                  class="col-auto notify-cmd"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>

              <CheckboxField
                v-if="
                  selectedProcess.stageType !== 'START' &&
                  (attachComment ||
                    !(attachmentList.length && fileNames.length))
                "
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                :class="notifyInitiatorClass"
                class="col-12 q-pl-sm q-pb-sm q-pt-sm"
              />

              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions q-pa-sm"
            />
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$emit('addTask')"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="$emit('viewTask', task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>
    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>
    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>
    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>
    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <div class="row actions advance-cmd">
            <template v-if="attachmentList.length && fileNames.length">
              <CheckboxField
                v-model="attachComment"
                label="Add Comment to PDF"
                class="col-auto q-pr-sm"
                style="margin: auto"
              />

              <SelectField
                v-if="attachComment"
                v-model="selectedFileForAttach"
                :is-searchable="true"
                :options="fileNames"
                class="field comment-attach"
              />
            </template>

            <CheckboxField
              v-if="selectedProcess.stageType !== 'START'"
              v-model="notifyInitiator"
              label="Notify Initiator/Requester"
              class="col-auto q-pl-sm adv-notify-cmd"
            />
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <MetaDataEdit
      :value="showMetaDataEditModal"
      mode="Indexing"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      title="Index"
      :columns="columns"
      :modal-value="selectedProcess"
      :upload-filename="uploadFilename"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <Sheet
      v-model="fileSheetInFileControl"
      no-padding
      width="100vw"
      @input="fileSheetInFileControl = false"
    >
      <!-- title -->

      <template #title>
        <template v-if="selectedFileInFileControl">
          <div class="attachmentFileHeader">
            <div class="avatar q-ml-sm">
              <FileIcon
                :mime-type="fileType(selectedFileInFileControl.fileName)"
              />
            </div>

            <div class="q-ml-md">
              <div class="filename">
                {{ selectedFileInFileControl.fileName }}
              </div>

              <div class="meta">
                <div>
                  Archived At: {{ $day.format(selectedProcess.raisedAt) }}
                </div>
                <BaseIcon name="mdi-circle-small" />
                <div>Archived By: {{ selectedProcess.raisedBy }}</div>
                <div v-if="selectedFile.size">
                  <BaseIcon name="mdi-circle-small" />
                  File Size: {{ fileSize(selectedFile.size) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-if="selectedFileInFileControl">
        <div class="attachmentFileFrame">
          <div class="file-list">
            <iframe
              id="file-frame"
              ref="myFiles"
              :src="fileControlFileLink()"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </template>
    </Sheet>

    <Summarizing
      v-model="summarizeModal"
      width="40vw"
      height="60vh"
      :workflow-id="workflow.id"
      :transaction-id="selectedProcess.transactionId"
      :process-id="selectedProcess.processId"
      :page="'WORKFLOW'"
      @input="summarizeModal"
    />

    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="false"
      :form-check-list="false"
      :check-list="checkList"
      :wform="form"
      :repository-details="repositoryDetails"
      :workflow-model="workflowModel"
      :form-upload-files="[]"
      :repository-field="repositoryField"
      :columns="columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="false"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="fileNames"
      :comments.sync="commentsList"
      :display-internal-public="displayInternalPublic"
      @commentsCount="commentsCount"
    />
  </div>
</template>
<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import ListItem from "@/components/common/ListItem.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import { repository, workflow, integration, menu } from "@/api/factory.js";
import Confirm from "@/components/common/popup/Confirm.vue";
import axios from "axios";
import EmailSharing from "@/components/common/EmailSharing.vue";
import FileDetails from "./FileDetails";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import Stepper from "@/components/common/workflow/Stepper.vue";
import Summarizing from "@/components/common/Summarizing.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";

export default {
  name: "FormsCard",

  components: {
    Sheet,
    TextAreaField,
    CheckboxField,
    SingleChoiceField,
    FileIcon,
    ListItem,
    Confirm,
    EmailSharing,
    FileDetails,
    TextBuilderField,
    Modal,
    MetaDataEdit,
    SelectField,
    Stepper,
    Summarizing,
    Attachments,
    Comments,
  },
  props: {
    workflow: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    selectedProcess: {
      type: Object,
      required: true,
      default: () => {},
    },
    processActions: {
      type: Array,
      default: () => [],
    },
    taskEntries: {
      type: Array,
      default: () => [],
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    jiraAccounts: {
      type: Array,
      default: () => [],
    },
    selectedNode: {
      type: String,
      default: "",
    },
    hideColumns: {
      type: Array,
      default: () => [],
    },
    portalAction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: "",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "show/hide columns",
          value: "TOGGLE",
          icon: "mdi-table-column-plus-after",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Payment Table",
          value: "PAYMENT_TABLE",
          icon: "mdi-table",
          count: 0,
        },
      ],
      attachmentSheet: false,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      attachmentLoad: null,
      commentsLoad: null,
      columns: [],
      searchColumns: "",
      signList: [],
      selectAll: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileDetailsSheet: false,
      fileData: [],
      taskSheet: false,
      taskList: [],
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      checkListRowIndex: null,
      errorMessage: "",
      errorModal: false,
      advancedCommentsSheet: false,
      advancedCommentText: "",
      repositoryField: [],
      showMetaDataEditModal: false,
      selectedUploadFile: [],
      repositoryDetails: {},
      reuploadId: 0,
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      documentLoad: null,
      watchViewerClose: null,
      uploadFilename: "",
      fileNames: [],
      selectedFileForAttach: 0,
      attachComment: false,
      displayInternalPublic: false,
      fileSheetInFileControl: false,
      selectedFileInFileControl: {},
      answerIndicatorList: {},
      stageLevel: [],
      currentStep: 0,
      showAnswerIndicator: false,
      notifyInitiator: false,
      summarizeModal: false,
      showAttachmentSheet: false,
      showCommentsSheet: false,
      workflowModel: {},
    };
  },

  computed: {
    isPreviewAction() {
      let origin = location.origin;
      if (origin === "http://localhost:8080") {
        return true;
      }
      //return false;
      return true;
    },

    scrollbarHeight() {
      const height = this.columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },

    selectedFileCount() {
      this.clearAttach();
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },

    isCustomizedWorkflow() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflow.id === 1014) {
          return true;
        }
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.id === 1) {
          return true;
        }
      }
      return false;
    },

    commentsSheetHeight() {
      if (this.selectedNode === "inbox" || this.selectedNode === "payment") {
        if (this.checkCommentsOption()) {
          if (this.attachmentList.length && this.fileNames.length) {
            if (this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 270px)";
            } else if (this.attachComment && !this.displayInternalPublic) {
              return "calc(100vh - 230px)";
            } else if (!this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 225px)";
            } else {
              return "calc(100vh - 200px)";
            }
          } else if (this.displayInternalPublic) {
            return "calc(100vh - 220px)";
          } else if (this.selectedProcess.stageType !== "START") {
            return "calc(100vh - 180px)";
          } else {
            return "calc(100vh - 150px)";
          }
        } else {
          return "calc(100vh - 150px)";
        }
      }
      return "";
    },

    checkStatusIcon() {
      if (this.selectedProcess.flowStatus == 1) {
        if (
          this.selectedProcess.lastAction.toLowerCase().indexOf("reject") > -1
        ) {
          return "eva-close-circle-outline";
        } else {
          return "eva-checkmark-circle-2-outline";
        }
      } else {
        return "mdi-progress-clock";
      }
    },

    checkStatusColor() {
      if (this.selectedProcess.flowStatus == 1) {
        if (
          this.selectedProcess.lastAction.toLowerCase().indexOf("reject") > -1
        ) {
          return "red";
        } else {
          return "positive";
        }
      } else {
        return "orange";
      }
    },

    notifyInitiatorClass() {
      return !(this.attachmentList.length && this.fileNames.length)
        ? "action-container"
        : "";
    },
  },

  watch: {
    selectedProcess: {
      immediate: true,
      async handler() {
        if (this.selectedProcess.processId) {
          this.attachmentList = [];
          this.fileData = [];
          this.getComments(false);
          this.getAttachments(false);
          if (this.selectedProcess.formData) {
            await this.getFormColumns();
          } else if (this.selectedProcess.itemData) {
            if (this.selectedProcess.itemData.items) {
              await this.getItemColumns();
            }
          }
          this.applyHideColumns();
          this.getProcessSignature();
          await this.getRepository();
          this.showHistoryStepper(
            this.selectedProcess.processId,
            this.selectedProcess.id
          );
          this.showAnswerIndicatorCount();
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          this.getAttachments();
          if (this.selectedProcess.flowStatus === "0") {
            if (this.attachmentList.length) {
              let index = this.attachmentList.length - 1;
              this.getFileData(this.attachmentList[index], "file");
            }
            this.attachmentLoad = setInterval(() => {
              this.getAttachments(false);
            }, 15000);
          }
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (
          this.commentsSheet &&
          this.selectedProcess.processId &&
          this.selectedProcess.flowStatus === "0"
        ) {
          this.commentsLoad = setInterval(() => {
            this.getComments(false);
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },
  },

  created() {
    localStorage.removeItem("viewerClose");
  },

  methods: {
    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
      this.notifyInitiator = false;
    },

    customCheckUpload() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" &&
        (this.workflow.id === 55 || this.workflow.id === 2092)
      ) {
        if (this.attachmentList.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        ((origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2)
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2 &&
          this.selectedProcess.jiraIssueInfo.id
        ) {
          return true;
        }
      }
      return false;
    },

    checkCommentsOption() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      } else if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }

        return false;
      }
      return true;
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      this.$refs.attachments.value = null;
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        // this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.displayInternalPublic = false;
        let wflowJson = JSON.parse(this.workflow.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (
            startBlock.settings.initiateBy.indexOf("PORTAL") > -1 ||
            this.portalAction
          ) {
            this.displayInternalPublic = true;
          }
        }
        this.fileNames = [];
        this.fileNames = this.attachmentList
          .filter((item) => {
            let extension = item.name.split(".").pop().toUpperCase();

            return extension.toLowerCase() === "pdf";
          })
          .map((item) => {
            return {
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            };
          });
        // this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY") {
        this.$emit("history", this.selectedProcess.processId);
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$emit("addTask");
        }
      } else if (tab === "VALIDATION") {
        this.$emit("validation", this.selectedProcess.processId);
      } else if (tab === "PAYMENT_TABLE") {
        this.$emit("paymentTable");
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateType(key) {
      let proceed = true;
      if (this.form.formJson) {
        let formJson = JSON.parse(this.form.formJson);
        formJson.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          for (let field of panel.fields) {
            if (field.id === key) {
              if (field.type === "TABLE") {
                proceed = false;
                return;
              }
            }
          }
        });
      }
      return proceed;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    requestAction(action) {
      this.$emit("proceed", action);
    },

    getLabel(id, type) {
      let controlLabel = "";
      if (this.form.formJson) {
        let formJson = JSON.parse(this.form.formJson);
        formJson.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          for (let field of panel.fields) {
            if (type === "table") {
              const column = field.settings.specific.tableColumns.find(
                (column) => column.id === id
              );
              if (column) {
                controlLabel = column.label;
              }
            } else {
              if (field.id === id) {
                controlLabel = field.label;
                return;
              }
            }
          }
        });
      }
      return controlLabel;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        let action = "";
        if (this.selectedNode === "inbox" || this.selectedNode === "payment") {
          action = "&action=all";
        }
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            action = "";
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            localStorage.setItem("viewerClose", false);
            this.watchViewerClose = setInterval(
              () => this.checkViewerClose(),
              1000
            );
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com1") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          localStorage.setItem("viewerClose", false);
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
      }
    },

    openFileByCmd(file) {
      const originalFile = file.embedFileList[0];
      const { fileName: name, itemid: id, ...rest } = originalFile;
      this.selectedFile = { name, id, ...rest };
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
        if (
          origin === "http://52.172.32.88" ||
          origin === "http://localhost:8080"
        ) {
          localStorage.setItem("viewerClose", false);
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
        }
      }
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          this.fileSheet = false;
          localStorage.removeItem("viewerClose");
          clearInterval(this.watchViewerClose);
        }
      }
    },

    getFormColumns() {
      let filterColumns = [];
      if (this.form.formJson) {
        let formJson = JSON.parse(this.form.formJson);
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let wflowJson = JSON.parse(this.workflow.flowJson);
        let secureControls = [];
        const controls = wflowJson.blocks.reduce((acc, control) => {
          if (
            control.settings.formSecureControls &&
            control.settings.formSecureControls.length
          ) {
            if (typeof control.settings.formSecureControls[0] === "string") {
              control.settings.users.forEach((user) => {
                acc.push({ [user]: control.settings.formSecureControls });
              });
            } else {
              control.settings.formSecureControls.forEach((user) => {
                if (user.formFields.length) {
                  acc.push({ [user.userId]: user.formFields });
                }
              });
            }
          }
          return acc;
        }, []);
        controls.forEach((user) => {
          if (user[this.$store.state.session.id]) {
            secureControls.push(...user[this.$store.state.session.id]);
          }
        });
        formFields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            if (secureControls.length) {
              if (typeof secureControls[0] === "object") {
                let user = secureControls.findIndex(
                  (item) => item.userId === this.$store.state.session.id
                );
                if (user >= 0) {
                  let controls = secureControls[user].formFields;
                  let index = controls.findIndex((item) => item === field.id);
                  if (index === -1) {
                    filterColumns.push({
                      id: field.id,
                      label: field.label,
                      isVisible: true,
                      display: "",
                    });
                  }
                }
              } else {
                let index = secureControls.findIndex(
                  (item) => item === field.id
                );
                if (index === -1) {
                  filterColumns.push({
                    id: field.id,
                    label: field.label,
                    isVisible: true,
                    display: "",
                  });
                }
              }
            } else {
              filterColumns.push({
                id: field.id,
                label: field.label,
                isVisible: true,
                display: "",
              });
            }
          }
        });
      }
      this.columns = filterColumns;
    },

    getItemColumns() {
      let filterColumns = [];
      var keys = Object.keys(this.selectedProcess.itemData.items);
      for (var i = 0; i < keys.length; i++) {
        filterColumns.push({
          id: keys[i],
          label: keys[i],
          isVisible: true,
          display: "",
        });
      }
      this.columns = filterColumns;
    },

    isVisible(key) {
      const columns = this.columns;
      const columnIdx = columns.findIndex((column) => column.id === key);
      if (columnIdx > -1) {
        return columns[columnIdx].isVisible;
      } else {
        return true;
      }
    },

    async searchColumnList(value) {
      this.columns.forEach((column) => {
        column.display = "";
        if (!column.label.toLowerCase().includes(value.toLowerCase())) {
          column.display = "hide";
        }
      });
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.workflow.id}/${id}`;
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    attachTemplate(uid) {
      this.checkListRowIndex = uid;
      this.$refs.attachments.click();
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    getJIRALink(link) {
      let url = new URL(link.self);
      let origin = url.origin.replace(
        "https://jira-external-tools-integration.getgotools.net",
        "https://jira.ops.expertcity.com"
      );
      window.open(`${origin}/browse/${link.key}`, "_blank");
    },

    // API Functions

    async getComments(loading = true) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (loading) {
        this.$store.commit("showLoadingBar");
      }
      const { error, payload } = await workflow.getProcessComments(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (loading) {
        this.$store.commit("hideLoadingBar");
      }

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      let origin = location.origin;
      if (this.commentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.commentAction = 1;
          this.clearAttach();
          this.getComments();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }

          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        }
      }
    },

    async uploadWflowAttachments(e) {
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            this.uploadFilename = e.target.files[0].name;
          }
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("workflowId", this.workflow.id);
      formData.append("repositoryId", this.workflow.repositoryId);
      formData.append("processId", this.selectedProcess.processId);
      formData.append("transactionId", this.selectedProcess.transactionId);
      if (fields) {
        formData.append("fields", fields);
      } else {
        formData.append("fields", "");
      }
      formData.append("file", file);
      let filename = e.target.files[0].name;
      const ext = filename.split(".").pop();
      if (this.checkListRowIndex) {
        let currentDoc = this.checkList.filter(
          (row) => row.uid === this.checkListRowIndex
        );
        formData.append("filename", currentDoc[0].name + "." + ext);
        filename = currentDoc[0].name + "." + ext;
      }
      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      this.$store.commit("showLoadingBar");
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        const { status, data } = response;
        if (status === 200 && data) this.$alert.success(`Document attached`);
        else throw response;
        this.documentLoad = setInterval(() => {
          this.getAttachments(false);
          clearInterval(this.documentLoad);
        }, 15000);
      } catch (error) {
        this.$alert.error("Error uploading file");
        console.error(error);
      }
      this.$store.commit("hideLoadingBar");
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.uid === this.checkListRowIndex) {
            row.attach = true;
            return;
          }
        });
      }
      e.target.value = "";
      if (this.reuploadId) {
        await this.attachmentDelete(this.reuploadId);
        this.reuploadId = 0;
      }
      this.attachmentLoad = setInterval(() => this.getAttachments(false), 5000);
    },

    async getAttachments(loading = true) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;
      if (loading) {
        this.$store.commit("showLoadingBar");
      }
      const { error, payload } = await workflow.getAttachments(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (loading) {
        this.$store.commit("hideLoadingBar");
      }

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      if (payload.length) {
        // let index = payload.length - 1;
        // this.getFileData(payload[index], "attach");
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
      }
    },

    async attachmentDelete(id) {
      this.$store.commit("showLoadingBar");
      const { error } = await repository.fileDelete(
        this.workflow.repositoryId,
        id
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getAttachments();
    },

    async getProcessSignature() {
      const { error, payload } = await workflow.getProcessSignature(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    async getFileData(file, attach) {
      //this.$store.commit("showLoadingBar");

      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });

      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      this.fileData = payload;
      if (!attach) this.fileDetailsSheet = true;
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      if (this.jiraCommentAction === 1) {
        let resourceIDFieldId = this.formFields.find(
          (field) => field.label === "Resource ID"
        )?.value;
        commentsText =
          this.selectedProcess.formData.fields[resourceIDFieldId] +
          "," +
          commentsText;
      }
      commentsText = `${this.$store.state.session.firstName} ${this.$store.state.session.lastName}: ${commentsText}`;
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
      this.jiraCommentAction = 1;
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });
      this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
    },

    validateDateType(key) {
      let proceed = false;
      if (this.form.formJson) {
        let formJson = JSON.parse(this.form.formJson);
        formJson.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          for (let field of panel.fields) {
            if (field.id === key) {
              if (field.type === "DATE") {
                proceed = true;
                return;
              }
            }
          }
        });
      }
      return proceed;
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflow.id,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    async applyHideColumns() {
      this.hideColumns.forEach((field) => {
        const columnIdx = this.columns.findIndex(
          (column) => column.id === field.columnName
        );
        if (columnIdx > -1) {
          this.columns[columnIdx].isVisible = false;
          this.columns[columnIdx].hideId = field.id;
        }
      });
    },

    async updateHideColumns(field) {
      const columnIdx = this.columns.findIndex(
        (column) => column.id === field.id
      );
      this.columns[columnIdx].isVisible = !this.columns[columnIdx].isVisible;
      let input = { id: field.hideId || 0, columnName: field.id };

      const { error, payload } = await menu.updateHideColumns(
        this.workflow.id,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      let hideCols = [];
      if (field.hideId) {
        hideCols = this.hideColumns.filter((col) => col.id != field.hideId);
      } else {
        hideCols = this.hideColumns;
        hideCols.push({
          id: payload,
          columnName: field.id,
        });
      }
      this.columns[columnIdx].hideId = field.hideId ? 0 : payload;
      this.$emit("update:hideColumns", hideCols);
    },

    validateFileUpload(key) {
      // console.log(key);
      let proceed = false;
      if (this.form.formJson) {
        let formJson = JSON.parse(this.form.formJson);
        formJson.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          for (let field of panel.fields) {
            if (field.id === key) {
              if (field.type === "FILE_UPLOAD") {
                proceed = true;
                return;
              }
            }
          }
        });
      }
      return proceed;
    },

    openFileInFileControl(fileObj) {
      this.selectedFileInFileControl = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileControlFileLink());
      } else {
        this.fileSheetInFileControl = true;
        if (
          origin === "http://52.172.32.88" ||
          origin === "http://localhost:8080"
        ) {
          localStorage.setItem("viewerClose", false);
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
        }
      }
    },

    fileControlFileLink() {
      // let link = "";
      let file = this.selectedFileInFileControl;
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (file.fileName) {
        let ext = file.fileName.split(".").pop();
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com1") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${file.fileName}`;
      }
    },

    showAnswerIndicatorCount() {
      this.showAnswerIndicator = false;
      if (this.form.formJson) {
        let formControls = [];
        let panels = JSON.parse(this.form.formJson);
        panels.panels.forEach((panel) => {
          formControls.push(...panel.fields);
        });
        let filledValue = 0;
        let nonFilledValue = 0;

        let fieldsModel = this.selectedProcess.formData.fields;

        formControls.forEach((control) => {
          if (this.isVisible(control.id)) {
            if (
              control.settings.validation.fieldRule === "REQUIRED" &&
              control.settings.general.visibility === "NORMAL"
            ) {
              if (control.settings.validation.answerIndicator === "YES") {
                this.showAnswerIndicator = true;
                if (Array.isArray(fieldsModel[control.id])) {
                  if (fieldsModel[control.id].length) {
                    filledValue += 1;
                  } else {
                    nonFilledValue += 1;
                  }
                } else if (fieldsModel[control.id] === "<p></p>") {
                  if (this.removeTags(fieldsModel[control.id])) {
                    filledValue += 1;
                  } else {
                    nonFilledValue += 1;
                  }
                } else if (fieldsModel[control.id]) {
                  filledValue += 1;
                } else {
                  nonFilledValue += 1;
                }
              }
            }
          }
        });
        this.answerIndicatorList = {
          filledValue: filledValue,
          nonFilledValue: nonFilledValue,
        };
      }
    },

    async showHistoryStepper(processId, wId) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        payload.forEach((row) => {
          if (row.actionStatus !== 2) {
            if (
              this.stageLevel.filter((stage) => stage.id === row.activityId)
                .length === 0
            ) {
              this.stageLevel.push({
                id: row.activityId,
                label: row.stage,
                status: row.status,
              });
              if (
                ((row.actionUser || row.subWorkflowHistory) &&
                  row.status &&
                  row.status !== "Forward") ||
                (row.actionUser === null && !row.subWorkflowHistory)
              ) {
                this.currentStep += 1;
              }
            } else if (row.status) {
              if (
                this.stageLevel.filter(
                  (stage) =>
                    stage.id === row.activityId && stage.status !== row.status
                ).length
              ) {
                this.currentStep += 1;
              } else {
                this.currentStep -= 1;
              }
            } else {
              let index = this.stageLevel.findIndex(
                (stage) => stage.id === row.activityId
              );
              if (index > -1) {
                this.stageLevel.splice(index, this.stageLevel.length - index);
                this.stageLevel.push({
                  id: row.activityId,
                  label: row.stage,
                  status: row.status,
                });
                if (
                  (row.actionUser && row.status && row.status !== "Forward") ||
                  row.actionUser === null
                ) {
                  this.currentStep = index + 1;
                } else {
                  this.currentStep = index;
                }
              }
            }
          }
        });
      }
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#workflowRequestPreview {
  #global-search {
    width: 92%;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid #eee;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }

  .plus {
    height: 20px !important;
    width: 20px !important;
  }

  .list {
    // background-color: $secondary-2;
    border-radius: 6px;
    // color: white !important;
    //   height: calc(100vh - 208px);
    height: 100%;
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    background-color: #fff !important;
    font-size: 12px !important;
    &.icon-only {
      padding: 0px !important;
      margin-right: 5px !important;
      border: 0px !important;
      .q-avatar {
        margin: 0px !important;
      }
    }
  }

  .forms {
    border-radius: 6px;
    height: 100%;
    border: 1px solid #eee;
    padding: 5px;
    text-align: center;
  }

  .header {
    text-align: center;
    color: $primary-11;
    font-weight: 500;
  }

  .btnColor {
    color: $primary-11 !important;
  }

  table {
    &.requestDetails {
      background: $primary-1;
    }

    &.jiraDetails {
      background: $secondary-1;
    }

    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    padding: 0px;
    border-radius: 10px;
    white-space: nowrap;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .text-underline {
    text-decoration: underline;
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .pink-hover:hover {
    background: $pink-2 !important;
  }

  .fullBorder {
    border: 1px solid var(--divider-color);
    border-radius: 10px;
  }

  .content {
    border-right: 1px solid var(--divider-color);
  }

  .processLink #action-btn {
    border-color: $secondary;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 10px;
    background: $secondary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  #action-btn.linkActions {
    width: 28px !important;
    height: 28px !important;
  }

  #approvalSignature {
    padding: 0px 16px 16px 16px;
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 60px;
    }
  }
}
.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}

#list-item.hide {
  display: none !important;
}

#sheet .borderTop {
  border: 1px solid var(--divider-color);
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .taskEntries {
  padding: 12px;

  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}

.theme-light {
  #workflowRequestPreview {
    table {
      &.requestDetails {
        background: $primary-1;
      }

      &.jiraDetails {
        background: $secondary-1;
      }
    }
  }
}

.theme-dark {
  #workflowRequestPreview {
    table {
      &.requestDetails {
        background: $primary-10;
      }

      &.jiraDetails {
        background: $secondary-10;
      }
    }
  }
}

.chip-warning {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chip-success {
  font-weight: 500;
  background-color: $positive;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mini-avatar {
  width: 18px !important;
  height: 18px !important;
}

.slaResponse {
  border: 1px solid $secondary;
  border-radius: 5px;
}

.slaResolution {
  border: 1px solid var(--divider-color);
  border-radius: 5px;
}

.stepper {
  border: 1px solid var(--divider-color);
  border-radius: 10px;
}
</style>

<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}
.advance-cmd {
  margin-right: auto;
}
.file-icon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.text-area-action {
  border: 1px solid var(--border-color);
  #field-wrapper {
    border: none !important;

    .is-focused {
      border: none;
    }
  }
}

.attach-cmd {
  #field-wrapper {
    height: 30px !important;
    .select {
      .value {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value:hover {
        max-width: none !important;
        overflow: visible;
        white-space: pre;
        position: fixed;
      }
    }
  }
}

.select-action {
  padding-top: 10px;
  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}
.comment-attach {
  #field-wrapper {
    height: 30px !important;
  }
}

.requestDetails {
  border: 1px solid var(--divider-color);
  border-radius: 5px;

  &.red {
    border-left: 5px solid red;
  }

  &.positive {
    border-left: 5px solid $positive;
  }

  &.orange {
    border-left: 5px solid $orange;
  }
}

.answer-count {
  border: 1px solid var(--divider-color);
  border-radius: 15px;
  padding: 2px 0px;
}
</style>

<style>
.q-stepper {
  box-shadow: none !important;
}

.q-stepper__header--border {
  border-bottom: 0px solid #fff !important;
}

.q-stepper__header--alternative-labels .q-stepper__tab {
  min-height: 0px !important;
  padding: 6px 24px !important;
}

/* .q-message-name--received {
  margin-left: 20px;
}
.q-message-name--sent {
  margin-right: 23px;
} */
</style>
